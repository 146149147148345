<template>
    <section id="dashboard" class="py-5" v-cloak>
        <the-banner-small>Site Management: beheer bedelingen</the-banner-small>
        <div class="container my-3">
            <div class="row justify-content-center">
                <div class="col-9 p-3">
                    <form @submit.prevent="createMoment">
                        <div class="row mb-3">
                            <label for="momentDate" class="col-sm-2 col-form-label">Datum</label>
                            <div class="col-sm-4">
                                <input type="date" class="form-control" id="momentDate" v-model="momentDate">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="timeFrom" class="col-sm-2 col-form-label">Begin</label>
                            <div class="col-sm-4">
                                <input type="time" class="form-control" id="timeFrom" v-model="timeFrom">
                            </div>
                            <label for="timeTo" class="col-sm-2 col-form-label">Tot</label>
                            <div class="col-sm-4">
                                <input type="time" class="form-control" id="timeTo" v-model="timeTo">
                            </div>
                        </div>
                        <input type="submit" value="Submit" class="btn btn-secondary btn-block">
                    </form>
                </div>
                <div class="col-9 p-3">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Jaar</th>
                                <th scope="col">Maand</th>
                                <th scope="col">Datum</th>
                                <th scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="moment in moments" :key=moment.id>
                                <td scope="row">{{ year(moment.date) }}</td>
                                <td scope="row">{{ month(moment.date) }}</td>
                                <td>{{ moment.date }}</td>
                                <td><button @click.prevent="deleteMoment(moment)" class="btn btn-sm btn-outline-secondary">Verwijder Bedeling</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import TheBannerSmall from '../../components/layout/TheBannerSmall'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ENV_BASE_URL

export default {
    data () {
        return {
            moments: [],
            momentDate: '',
            timeFrom: '',
            timeTo: ''
        }
    },
    components: {
        TheBannerSmall
    },
    created () {
        this.getMoments()
    },
    methods: {
        async createMoment () {
            if (new Date(this.momentDate) > new Date()) {
                const moment = {
                    momentDate: this.momentDate,
                    timeFrom: this.timeFrom,
                    timeTo: this.timeTo
                }
                await this.$store.dispatch('moments/addMoment', moment)
                this.resetForm()
                this.getMoments()
            }
        },
        async deleteMoment(moment) {
             axios.delete('/api/v1/management/moments/' + moment.id)
                .then(response => {
                    this.moments.splice(this.moments.indexOf(moment), 1)
                })
        },
        year(date) {
            return new Date(date).getUTCFullYear()
        },
        month(date) {
            return this.monthTranslated(new Date(date).getUTCMonth())
        },
        getMoments() { // Should be a store getter
            this.moments = []
            axios.get('/api/v1/management/moments').then(response => {
                this.moments = response.data.data
            })
        },
        resetForm() {
            this.momentDate = ''
            this.timeFrom = ''
            this.timeTo = ''
        },
        monthTranslated(number) {
            let month
            switch (number) {
                case 0:
                    month = 'Januari'
                    break
                case 1:
                    month = 'Februari'
                    break
                case 2:
                    month = 'Maart'
                    break
                case 3:
                    month = 'April'
                    break
                case 4:
                    month = 'Mei'
                    break
                case 5:
                    month = 'Juni'
                    break
                case 6:
                    month = 'Juli'
                    break
                case 7:
                    month = 'Augustus'
                    break
                case 8:
                    month = 'September'
                    break
                case 9:
                    month = 'Oktober'
                    break
                case 10:
                    month = 'November'
                    break
                case 11:
                    month = 'December'
                    break
                default:
                    month = 'undefined'
                    break
            }
            return month
        }
    }
}
</script>
