<template>
  <!-- SHOWCASE -->
  <div id="showcase" class="py-5">
    <div class="primary-overlay text-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-left pt-4 pt-lg-5">
            <h1 class="display-5 bold mx-auto">
              <img src="@/assets/images/banner-logo-small.jpg" alt="" class="img-fluid d-none d-lg-inline mx-3">
              <slot></slot>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
    color: rgb(210, 77, 87);
}
#showcase {
    height: 250px;
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    border-bottom: 1px solid rgb(210, 77, 87);
}

#showcase .primary-overlay {
    background: rgba(20, 29, 42, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
}
.info-header {
  width: 50%;
  margin: auto;
  border-bottom: 1px #ddd solid;
}

.bold {
    font-weight:400;
}

@media (max-width: 991.98px) {
    #showcase {
        height: 100px;
    }

    #showcase .primary-overlay {
        height: 100%;
    }

    #showcase h1 {
        font-size: 2rem;
        color: white;
    }

    .mb-resp {
        margin-bottom: 1rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    #showcase {
        height: 250px;
    }

    #showcase h1 {
        font-size: 3rem;
    }

    .mb-resp {
        margin-bottom: 1rem;
    }
}
</style>
